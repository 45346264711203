<template>
  <v-container fluid>
    <head-title text="Vehículos" :to="{ name: 'fleet-home' }"></head-title>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="searchText"
          label="Buscar"
          placeholder="Buscar por Código o placa"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn class="mx-2" fab dark small color="primary">
          <v-icon dark @click="getVehicles()"> mdi-refresh </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="3" class="text-end">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="primary"
          @click="createVehicle()"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="vehicles"
      :loading="loading"
      hide-default-footer
      disable-sort
      disable-pagination
      class="elevation-1"
    >
    </v-data-table>
    <new-vehicle
      :dialog="dialogVehicle"
      @close="dialogVehicle = false"
      @refresh="getVehicles()"
    ></new-vehicle>
    <v-pagination
      v-model="currentPage"
      :total-visible="10"
      :length="totalPages"
      :disabled="loading"
      v-show="totalPages > 0"
    ></v-pagination>
  </v-container>
</template>

<script>
import request from "@/services/requests";

export default {
  name: "VehicleList",
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    NewVehicle: () => import("@/components/fleets/NewVehicle"),
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 0,
      componentKey: 0,
      title: "",
      searchText: "",
      vehicles: [],
      loading: false,
      VehicleId: 0,
      options: 0,
      dialogVehicle: false,
      headers: [
        { text: "Código", value: "code" },
        { text: "Categoría", value: "category" },
        { text: "Marca", value: "manufacturer" },
        { text: "Línea", value: "model" },
        { text: "Placa", value: "vehicleid" },
        { text: "Año", value: "year" },
      ],
    };
  },
  mounted() {
    this.getVehicles();
  },
  methods: {
    getVehicles() {
      this.loading = true;
      request
        .get(
          "api/catalogs/vehicles/?search=" +
            this.searchText +
            "&page=" +
            this.currentPage
        )
        .then((data) => {
          this.vehicles = data.data.results;
          this.searchText = "";
          this.totalPages = data.data.total_pages;
          this.loading = false;
        });
    },
    createVehicle() {
      this.dialogVehicle = true;
      this.options = 1;
      this.title = "Nuevo Vehículo";
    },
  },
  watch: {
    currentPage: function () {
      this.getVehicles();
    },
  },
};
</script>